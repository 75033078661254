import React, { FC, useCallback, useRef } from 'react';
import { Box, Flex } from '@rebass/grid';
import styled from 'styled-components';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Dropdown, { DropdownDescription } from './designsystem/Dropdown';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import ToggleSwitchWithText from '@oberoninternal/travelbase-ds/components/form/ToggleSwitchWithText';
import DatepickerField from './DatepickerField';
import Fieldset from './designsystem/Fieldset';
import { CLASS_SEARCH_BOX_FIELDSET } from '../constants/customizationClassNames';
import { useField, useFormikContext } from 'formik';
import { SearchBarValues } from './SearchBox';
import FlexibleDateContent from './FlexibleDateContent';
import parse from 'date-fns/parse';
import { isSameYear } from 'date-fns';
import { Booking } from '../entities/Booking';
import useDatepicker from '../hooks/useDatepicker';
import { convertToPeriod } from '../utils/trip';
import useOnClickOutside from 'use-onclickoutside';

interface Props {
    isHomepage: boolean;
    hideFlexibleOptions?: boolean;
    variant?: 'field' | 'inline';
}

export const getSearchDateLabel = (booking: Booking, formatDate: IntlShape['formatDate']) => {
    const parsedFlexibleMonth = booking.flexibleMonth && parse(booking.flexibleMonth, 'MM-yyyy', new Date());

    switch (booking.type) {
        case 'flexible-weekends':
        case 'flexible-random':
            if (booking.amountOfNights && parsedFlexibleMonth) {
                return (
                    <span>
                        <FormattedMessage
                            defaultMessage="{amountOfNights, number} {amountOfNights, plural, one {nacht} other {nachten}} in {month}"
                            values={{
                                amountOfNights: booking.amountOfNights,
                                month: formatDate(parsedFlexibleMonth, {
                                    month: 'long',
                                    ...(!isSameYear(new Date(), parsedFlexibleMonth) ? { year: 'numeric' } : {}),
                                }),
                            }}
                        />
                    </span>
                );
            }
            return defaultLabel;
        case 'static':
        default:
            if (booking.arrivalDate && booking.departureDate) {
                return (
                    <span>
                        {formatDate(booking.arrivalDate, {
                            format: 'DATERANGEPICKER',
                        })}{' '}
                        -{' '}
                        {formatDate(booking.departureDate, {
                            format: 'DATERANGEPICKER',
                        })}
                    </span>
                );
            }
            return defaultLabel;
    }
};

const SearchDateField: FC<React.PropsWithChildren<Props>> = ({
    isHomepage,
    hideFlexibleOptions,
    variant = 'field',
}) => {
    const [, startMeta] = useField('booking.arrivalDate');
    const [, endMeta] = useField('booking.departureDate');
    const initialValue = convertToPeriod(startMeta.initialValue, endMeta.initialValue);

    const { values, setFieldValue } = useFormikContext<SearchBarValues>();
    const { formatDate } = useIntl();
    const datepicker = useDatepicker(
        {
            singleDate: false,
            optionalSingleDate: false,
            period: initialValue,
            open: false,
        },
        variant === 'inline'
    );
    const [{ open, ...state }, { set }] = datepicker;
    const onToggle = useCallback(() => {
        set({
            ...state,
            open: !open,
            focus: !open ? 'start' : undefined,
        });
    }, [set, state, open]);
    const ref = useRef(null);
    useOnClickOutside(ref, () => open && onToggle());
    const renderInputs = () => (
        <>
            {!hideFlexibleOptions && (
                <Flex
                    py={[16, null, variant === 'field' ? 0 : 16]}
                    px={16}
                    justifyContent="center"
                    className="toggleSwitchContainer"
                >
                    <ToggleSwitchWithText
                        name="dateSwitch"
                        active={values.booking.type === 'static' ? 'static' : 'flexible'}
                        setActive={key => setFieldValue('booking.type', key === 'flexible' ? 'flexible-random' : key)}
                        options={[
                            { key: 'static', label: <FormattedMessage defaultMessage="Datums kiezen" /> },
                            {
                                key: 'flexible',
                                label: <FormattedMessage defaultMessage="Flexibel zoeken" />,
                            },
                        ]}
                    />
                </Flex>
            )}

            {(values.booking.type === 'static' || hideFlexibleOptions) && (
                <Fieldset label="" dataCy="searchFormDates" className={CLASS_SEARCH_BOX_FIELDSET}>
                    <DatepickerField
                        scrollIntoView={false}
                        startName="booking.arrivalDate"
                        endName="booking.departureDate"
                        hideInput
                        hasFlexibleDates
                        datepicker={datepicker}
                        singleDate={false}
                        ignoreClickOutside
                        variant={variant}
                    />
                </Fieldset>
            )}
            {values.booking.type !== 'static' && !hideFlexibleOptions && <FlexibleDateContent />}
        </>
    );

    return variant === 'field' ? (
        <StyledDropdown
            borderRadius={0}
            popupPlacement={isHomepage ? 'left' : 'bottom-start'}
            rounded={isHomepage}
            modifiers={{
                flip: {
                    enabled: false,
                },
                // custom computeStyle function to center the popper
                computeStyle: {
                    enabled: true,
                    fn(data) {
                        // eslint-disable-next-line no-param-reassign
                        data.styles = {
                            ...data.styles,
                            position: 'absolute',
                            willChange: 'transform',
                            top: '0px',
                            left: '-50%',
                            transform: 'translate3d(0px, 58px, 0px)',
                        };

                        return data;
                    },
                },
            }}
            surfaceElement={
                <Flex flexWrap="wrap">
                    {!isHomepage && (
                        <Box width={1}>
                            <StyledBody variant={isHomepage ? 'regular' : 'tiny'}>
                                <FormattedMessage defaultMessage="Reisperiode" />
                            </StyledBody>
                        </Box>
                    )}
                    <Box width={1}>
                        <Box>
                            <DropdownDescription variant={isHomepage ? 'regular' : 'tiny'}>
                                {getSearchDateLabel(values.booking, formatDate)}
                            </DropdownDescription>
                        </Box>
                    </Box>
                </Flex>
            }
            open={open}
            onToggle={onToggle}
            ref={ref}
        >
            <Box
                py={[3, 5]}
                width={['100%', '50rem', '50rem', '80rem']}
                style={{ maxWidth: '100vw', textAlign: 'center' }}
            >
                {renderInputs()}
            </Box>
        </StyledDropdown>
    ) : (
        renderInputs()
    );
};
export default SearchDateField;

const StyledBody = styled(Body)`
    color: ${({ theme }) => theme.colors.neutral['60']};
    font-weight: 300 !important;
    line-height: 1.15;
    white-space: nowrap;
    text-align: left;
`;

const StyledDropdown = styled(Dropdown)<{ rounded?: boolean }>`
    --borderRadius: ${({ theme, rounded }) => rounded && theme.radius.textInput};
`;

const defaultLabel = (
    <span>
        <FormattedMessage defaultMessage="Kies reisperiode" />
    </span>
);

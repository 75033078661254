import { useEffect } from 'react';
import { SearchBarValues } from '../../components/SearchBox';
import { Booking } from '../../entities/Booking';
import { useFormikContext } from 'formik';
import { useTenantContext } from '../../context/TenantContext';
import { getAccommodationTypeOptions } from '../../utils/getAccommodationTypeOptions';
import { useIntl } from 'react-intl';
import getDefaultAccommodationType from '../../utils/getDefaultAccommodationType';

const getHumanReadableBookingType = (type: Booking['type']) => {
    switch (type) {
        case 'flexible-random':
            return 'Flexibel (willekeurig)';
        case 'flexible-weekends':
            return 'Flexibel (weekends)';
        case 'static':
            return 'Datum';
        case 'coming-month':
            return 'Komende maand';
        default:
            return 'Onbekend';
    }
};

// pushes changes in the searchbarvalues to the datalayer every time the form gets submitted
const useAnalyticsSearchBarValues = (values: SearchBarValues) => {
    const { isSubmitting } = useFormikContext();
    const { brandConfig } = useTenantContext();
    const intl = useIntl();
    const accommodationTypeOptions = getAccommodationTypeOptions(intl, brandConfig);
    useEffect(() => {
        if (isSubmitting) {
            window.dataLayer = window.dataLayer ?? [];
            const { type, trip, arrivalDate, departureDate, flexibleMonth, amountOfNights, ...booking } =
                values.booking;
            const { accommodationType } = values;
            const isFlexible = type?.startsWith('flexible');
            window.dataLayer.push({
                event: isFlexible ? 'flexiblesearch' : 'normalsearch',
                searchType: getHumanReadableBookingType(type),
                ...booking,
                departureDate: !isFlexible ? departureDate : null,
                arrivalDate: !isFlexible ? arrivalDate : null,
                flexibleMonth: isFlexible ? flexibleMonth : null,
                amountOfNights: isFlexible ? amountOfNights : null,
                accommodationType: accommodationTypeOptions.find(
                    ({ value }) => value === accommodationType ?? getDefaultAccommodationType(brandConfig)
                )?.label,
            });
        }
    }, [values, isSubmitting, brandConfig, accommodationTypeOptions]);
};
export default useAnalyticsSearchBarValues;

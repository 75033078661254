import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Box, Flex, BoxProps } from '@rebass/grid';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import { CLASS_FIELDSET_LABEL } from '../../constants/customizationClassNames';

type LabelVariant = 'large' | 'regular' | 'small';

interface Props extends Omit<BoxProps, 'label'> {
    label: ReactNode;
    secondLabel?: ReactNode;
    id?: string;
    variant?: LabelVariant;
    dataCy?: string;
    className?: string;
    overwriteLabelColor?: string;
}

const Fieldset: FC<React.PropsWithChildren<Props>> = ({
    children,
    label,
    secondLabel,
    id,
    variant,
    dataCy,
    overwriteLabelColor,
    ...props
}) => (
    <FormBox data-cy={dataCy} {...props}>
        {(label || secondLabel) && (
            <SuperScript className={CLASS_FIELDSET_LABEL} overwriteLabelColor={overwriteLabelColor}>
                {label && (
                    <Label htmlFor={id} variant={variant}>
                        {label}
                    </Label>
                )}
                {secondLabel && (
                    <Label htmlFor={id} variant={variant}>
                        {secondLabel}
                    </Label>
                )}
            </SuperScript>
        )}
        {children}
    </FormBox>
);

export default Fieldset;

const SuperScript = styled(Flex)<{ overwriteLabelColor?: string }>`
    margin-bottom: ${({ theme }) => theme.spacing['30_Small']};

    position: relative;
    color: ${({ theme }) => theme.colors.neutral['90']};

    ${Label} {
        font-weight: 600;
        ${({ overwriteLabelColor }) => overwriteLabelColor && `color: ${overwriteLabelColor}`};

        @media screen and (max-width: ${({ theme }) => theme.mediaQueries.m}) {
            font-size: 14px;
        }

        :nth-of-type(2) {
            position: absolute;
            left: 50%;

            @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
                left: 50%;
            }
        }
    }
`;

export const FormBox = styled(Box)`
    margin-bottom: 2rem;

    &:focus-within {
        z-index: 10;
    }

    @media screen and (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        margin-left: 0;
    }
`;

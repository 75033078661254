import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import Modal from '@oberoninternal/travelbase-ds/components/layout/Modal';
import ModalHeader from '@oberoninternal/travelbase-ds/components/layout/ModalHeader';
import Stack from './Stack';
import { getAccommodationTypeOptions } from '../utils/getAccommodationTypeOptions';
import Formed from './Formed';
import { SearchBarValues } from './SearchBox';
import { useTenantContext } from '../context/TenantContext';
import SearchFormMobile from './SearchFormMobile';

interface Props {
    open: boolean;
    onClose: () => void;
    submitForm: () => void;
    setValues: (values: SearchBarValues, shouldValidate?: boolean) => void;
    values: SearchBarValues;
}

const SearchBoxModal: FC<React.PropsWithChildren<Props>> = ({ open, onClose, setValues, values, submitForm }) => {
    const intl = useIntl();
    const { brandConfig } = useTenantContext();
    const accommodationTypeOptions = getAccommodationTypeOptions(intl, brandConfig);

    return (
        <StyledModal open={open} onClose={onClose} variant="regular">
            <ModalHeader style={{ textTransform: 'capitalize' }}>
                <FormattedMessage defaultMessage="Zoeken" />
            </ModalHeader>
            <Stack my={6} spacing={4}>
                <Formed
                    handleSubmit={innerValues => {
                        setValues({ ...values, ...innerValues });
                        return submitForm();
                    }}
                    initialValues={values}
                    skipPrompt
                >
                    {({ submitForm: submitInner, isSubmitting }) => (
                        <>
                            <SearchFormMobile accommodationTypeOptions={accommodationTypeOptions} />
                            <SearchButton
                                size="large"
                                onClick={async () => {
                                    await submitInner();
                                    onClose();
                                }}
                                data-cy="searchButton"
                                submitting={isSubmitting}
                            >
                                <FormattedMessage defaultMessage="Zoeken" />
                            </SearchButton>
                        </>
                    )}
                </Formed>
            </Stack>
        </StyledModal>
    );
};

export default SearchBoxModal;

const SearchButton = styled(Button)`
    margin-top: ${({ theme }) => theme.spacing['60_Large']};
    border-radius: ${({ theme }) => theme.radius.button};
    width: 100%;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
        margin-top: ${({ theme }) => theme.spacing['50_Semi']};
    }
`;

const StyledModal = styled(Modal)`
    && {
        @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
            > [data-reach-dialog-content] {
                overflow: unset;
            }
        }
    }
`;

import React, { ComponentProps, FC } from 'react';
import TripGroupInput from './TripGroupInput';
import { useField } from 'formik';
import { Booking } from '../entities/Booking';
import { useTenantContext } from '../context/TenantContext';

const TripGroupInputField: FC<
    React.PropsWithChildren<{
        name: string;
        className?: string;
        onClose?: () => void;
        open?: boolean;
        variant?: ComponentProps<typeof TripGroupInput>['variant'];
        maxOccupancy?: number;
        minOccupancy?: number;
        surfaceElementVariant?: 'searchbar' | 'input';
        petsAllowed?: number;
    }>
> = ({
    name,
    className,
    variant = 'dropdown',
    onClose,
    open,
    maxOccupancy,
    minOccupancy,
    petsAllowed,
    surfaceElementVariant,
}) => {
    const { brandConfig } = useTenantContext();

    const [{ value }, , { setValue }] = useField<Booking>(name);
    return (
        <TripGroupInput
            className={className}
            loading={false}
            booking={value}
            surfaceElementVariant={surfaceElementVariant}
            setBooking={booking => {
                setValue(booking);
            }}
            maxOccupancy={brandConfig.searchBoxGroup?.maxOccupancy ?? maxOccupancy}
            minOccupancy={brandConfig.searchBoxGroup?.minOccupancy ?? minOccupancy}
            petsAllowed={petsAllowed}
            onToggle={onClose}
            open={open}
            width={1}
            dropDownBorderRadius={0}
            variant={variant}
        />
    );
};

export default TripGroupInputField;

import { defineMessages } from 'react-intl';

const accommodationMessages = defineMessages({
    roomOrHome: {
        defaultMessage: 'Kamer/vakantiehuis',
    },
    vacationHomes: {
        defaultMessage: 'Alléén vakantiehuizen',
    },
    hotelOrBnB: {
        defaultMessage: 'Alléén bij hotel of B&B',
    },
    bnbOrRoom: {
        defaultMessage: 'B&B of kamerverhuur',
    },
    bnb: {
        defaultMessage: 'B&B',
    },
    hotel: {
        defaultMessage: 'Hotel',
    },
    camping: {
        defaultMessage: 'Kamperen',
    },
    groupAccommodations: {
        defaultMessage: 'Groepsaccommodaties',
    },
    tinyHouse: {
        defaultMessage: 'Tiny house',
    },
    tent: {
        defaultMessage: 'Ingerichte tent',
    },
    caravan: {
        defaultMessage: 'Caravan',
    },
    camperPitch: {
        defaultMessage: 'Camperplaats',
    },
    boat: {
        defaultMessage: 'Boot',
    },
    chalet: {
        defaultMessage: 'Chalet',
    },
    apartment: {
        defaultMessage: 'Appartement',
    },
    beachHouse: {
        defaultMessage: 'Strandhuis',
    },
    all: {
        defaultMessage: 'Volledig aanbod',
    },
});

export default accommodationMessages;

import { IntlShape } from 'react-intl';
import { OptionType } from '@oberoninternal/travelbase-ds/components/form/SelectInput';
import accommodationMessages from '../constants/accommodationMessages';
import { BrandConfig } from '../constants/brandConfig';

// eslint-disable-next-line import/prefer-default-export
export const getAccommodationTypeOptions = (intl: IntlShape, brandConfig: BrandConfig): OptionType[] => {
    const { formatMessage } = intl;

    return brandConfig.accommodationTypes.map(({ message, value }) => ({
        label: formatMessage(accommodationMessages[message]),
        value,
    }));
};

import validInterval from '@oberoninternal/travelbase-ds/utils/validInterval';
import React, { FC } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Booking } from '../entities/Booking';
import getTripGroupText from '../utils/getTripGroupText';
import { convertToPeriod } from '../utils/trip';
import messages from '../constants/messages';
import styled from 'styled-components';
import { isSameYear } from 'date-fns';
import parse from 'date-fns/parse';

interface Props {
    booking: Booking;
    onlyShowPersons?: boolean;
    accommodationType?: string;
}

const BookingText: FC<React.PropsWithChildren<Props>> = ({ booking, accommodationType, onlyShowPersons = false }) => {
    const { arrivalDate, departureDate, type, ...amounts } = booking ?? {};

    const amountGuests =
        (amounts.amountAdults ?? 0) +
        (amounts.amountBabies ?? 0) +
        (amounts.amountChildren ?? 0) +
        (amounts.amountYouths ?? 0);

    const intl = useIntl();
    const { formatList, formatMessage } = useIntl();
    const guestsText = onlyShowPersons
        ? formatList([formatMessage(messages.guests, { amountGuests })])
        : getTripGroupText(intl, { ...amounts });
    const period = convertToPeriod(arrivalDate, departureDate);
    const parsedFlexibleMonth = booking.flexibleMonth && parse(booking.flexibleMonth, 'MM-yyyy', new Date());
    return (
        <StyledText>
            {guestsText}
            {type !== 'static' && parsedFlexibleMonth && (
                <>
                    {', '}
                    <FormattedMessage
                        defaultMessage="{amountOfNights, number} {amountOfNights, plural, one {nacht} other {nachten}} in {month}"
                        values={{
                            amountOfNights: booking.amountOfNights,
                            month: intl.formatDate(parsedFlexibleMonth, {
                                month: 'long',
                                ...(!isSameYear(new Date(), parsedFlexibleMonth) ? { year: 'numeric' } : {}),
                            }),
                        }}
                    />
                </>
            )}
            {type === 'static' && validInterval(period) && (
                <>
                    {', '}
                    <>
                        <FormattedDate value={period.start} day="numeric" month="short" />
                        {' — '}
                        <FormattedDate value={period.end} day="numeric" month="short" />
                    </>
                </>
            )}
            <span className="gt-m">{accommodationType && `, ${accommodationType}`}</span>
        </StyledText>
    );
};

const StyledText = styled.span`
    line-height: 1.4;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

export default BookingText;

import { useField } from 'formik';
import { OptionType } from '@oberoninternal/travelbase-ds/components/form/SelectInput';
import styled, { css } from 'styled-components';
import Stack from './Stack';
import React, { FC } from 'react';

interface Props {
    name: string;
    options: OptionType[];
    onChange?: (option: OptionType) => void;
}

const InlineSelectField: FC<React.PropsWithChildren<Props>> = ({ name, options, onChange }) => {
    const [{ value }, , { setValue, setTouched }] = useField(name);

    return (
        <Stack spacing={4}>
            {options.map(option => (
                <Item
                    key={option.value}
                    isSelected={value === option.value}
                    onClick={() => {
                        setValue(option.value);
                        setTouched(true);
                        onChange?.(option);
                    }}
                >
                    {option.label}
                </Item>
            ))}
        </Stack>
    );
};

export default InlineSelectField;

const Item = styled.div<{ isSelected: boolean }>`
    appearance: none;
    margin: 0;
    padding: 1.4rem 1.6rem;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    border-radius: 0.8rem;

    //color: #393939;

    ${({ isSelected }) =>
        isSelected &&
        css`
            background: ${({ theme }) => theme.colors.primary['80']};
            color: ${({ theme }) => theme.colors.neutral['0']};
        `};
`;

import React, { ComponentProps, FC, useMemo, useState } from 'react';
import styled from 'styled-components';
import getTripGroupText from '../utils/getTripGroupText';
import { useFormikContext } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { SearchBarValues } from './SearchBox';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import SearchForm, { Interactable } from './SearchForm';
import TripGroupInputField from './TripGroupInputField';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import { Box } from '@rebass/grid';
import Stack from './Stack';
import SearchDateField, { getSearchDateLabel } from './SearchDateField';
import InlineDropdownField from './InlineDropdownField';

type Props = Pick<ComponentProps<typeof SearchForm>, 'accommodationTypeOptions' | 'brandOptions'>;

const SearchFormMobile: FC<React.PropsWithChildren<Props>> = ({ accommodationTypeOptions }) => {
    const intl = useIntl();
    const [expanded, setExpanded] = useState<Interactable | null>(null);
    const {
        values: { booking, accommodationType },
    } = useFormikContext<SearchBarValues>();
    const tripGroupLabel = useMemo(() => getTripGroupText(intl, booking), [booking, intl]);
    const searchDateLabel = useMemo(() => getSearchDateLabel(booking, intl.formatDate), [booking, intl.formatDate]);

    return (
        <Stack spacing={4}>
            <Field>
                {expanded !== 'tripgroup' && (
                    <Box p={4} onClick={() => setExpanded('tripgroup')}>
                        <FieldCollapsed>
                            <FieldLabel>
                                <FormattedMessage defaultMessage="Reisgezelschap" />
                            </FieldLabel>
                            <FieldValue>
                                {tripGroupLabel ?? <FormattedMessage defaultMessage="Reisgezelschap" />}
                            </FieldValue>
                        </FieldCollapsed>
                    </Box>
                )}
                {expanded === 'tripgroup' && (
                    <>
                        <Box p={4} onClick={() => setExpanded(null)}>
                            <Title variant="large">
                                <FormattedMessage defaultMessage="Reisgezelschap" />
                            </Title>
                        </Box>

                        <Box p={4} pt={0}>
                            <TripGroupInputField name="booking" variant="inputs" />
                        </Box>
                    </>
                )}
            </Field>
            <Field>
                {expanded !== 'datepicker' && (
                    <Box p={4} onClick={() => setExpanded('datepicker')}>
                        <FieldCollapsed>
                            <FieldLabel>
                                <FormattedMessage defaultMessage="Reisperiode" />
                            </FieldLabel>
                            <FieldValue>{searchDateLabel}</FieldValue>
                        </FieldCollapsed>
                    </Box>
                )}
                {expanded === 'datepicker' && (
                    <>
                        <Box pb={4}>
                            <SearchDateField hideFlexibleOptions={false} isHomepage={false} variant="inline" />
                        </Box>
                    </>
                )}
            </Field>
            <Field>
                {expanded !== 'accommodationType' && (
                    <Box p={4} onClick={() => setExpanded('accommodationType')}>
                        <FieldCollapsed>
                            <FieldLabel>
                                <FormattedMessage defaultMessage="Type accommodatie" />
                            </FieldLabel>
                            <FieldValue>
                                {accommodationTypeOptions.find(opt => opt.value === accommodationType)?.label ?? (
                                    <FormattedMessage defaultMessage="Accommodatie type" />
                                )}
                            </FieldValue>
                        </FieldCollapsed>
                    </Box>
                )}
                {expanded === 'accommodationType' && (
                    <>
                        <Box p={4} onClick={() => setExpanded(null)}>
                            <Title variant="large">
                                <FormattedMessage defaultMessage="Type accommodatie" />
                            </Title>
                        </Box>
                        <Box p={4} pt={0}>
                            <InlineDropdownField
                                options={accommodationTypeOptions}
                                name="accommodationType"
                                onChange={() => setExpanded(null)}
                            />
                        </Box>
                    </>
                )}
            </Field>
        </Stack>
    );
};
export default SearchFormMobile;

const Field = styled.div`
    background: ${({ theme }) => theme.colors.neutral[0]};
    border: 1px solid #a8a8a8;
    box-shadow: 0px 24px 14px rgba(0, 0, 0, 0.01), 0px 11px 11px rgba(0, 0, 0, 0.02), 0px 3px 6px rgba(0, 0, 0, 0.03),
        0px 0px 0px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    color: ${({ theme }) => theme.colors.neutral[70]};

    .toggleSwitchContainer {
        border-bottom: 1px solid #a8a8a8;
    }
`;

const FieldLabel = styled(Body)``;

const FieldValue = styled(Body)`
    font-weight: 600;
`;

const FieldCollapsed = styled.div`
    display: flex;
    justify-content: space-between;
`;

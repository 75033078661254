import SelectInput, { OptionType } from '@oberoninternal/travelbase-ds/components/form/SelectInput';
import { getAssignmentColor } from '@oberoninternal/travelbase-ds/constants/theme';
import { useThemeContext } from '@oberoninternal/travelbase-ds/hooks/useThemeContext';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import {
    CLASS_SEARCH_BOX_ACCOMMODATION_TYPE_FIELD,
    CLASS_SEARCH_BOX_FIELDSET,
    CLASS_SEARCH_BOX_TRIP_GROUP_FIELD,
} from '../constants/customizationClassNames';
import { useTenantContext } from '../context/TenantContext';
import DatepickerField from './DatepickerField';
import Fieldset from './designsystem/Fieldset';
import StyledCaret from './designsystem/StyledCaret';
import TripGroupInputField from './TripGroupInputField';
import SearchDateField from './SearchDateField';
import { useDeviceSize } from '@oberoninternal/travelbase-ds/context/devicesize';
import SearchDateFieldMobile from './SearchDateFieldMobile';

export type SearchVariant = 'home' | 'homeBar' | 'searchpage' | 'hero' | 'accommodationSearch' | 'searchMap';

export const interactables = ['tripgroup', 'datepicker', 'accommodationType'] as const;
export type Interactable = (typeof interactables)[number];
interface Props {
    variant: SearchVariant;
    accommodationTypeOptions: OptionType[];
    brandOptions?: OptionType[];
    searchModalOpen?: boolean;
    hideCalendarIcon?: boolean;
    hideFlexibleOptions?: boolean;
    active?: Interactable[];
    hideHelper?: boolean;
}

const SearchForm: FC<React.PropsWithChildren<Props>> = ({
    variant,
    accommodationTypeOptions,
    brandOptions,
    searchModalOpen = false,
    active = interactables,
    hideCalendarIcon,
    hideHelper,
    hideFlexibleOptions,
}) => {
    const isSearchPage = variant === 'searchpage';
    const isHero = variant === 'hero';
    const isAccommodationSearch = variant === 'accommodationSearch';
    const isHomePage = variant === 'home';
    const showLabel = isHomePage || searchModalOpen;
    const isHomeBar = variant === 'homeBar';

    const isFull = isSearchPage || isHero || isAccommodationSearch || isHomeBar;
    const { brandConfig } = useTenantContext();
    const intl = useIntl();
    const theme = useThemeContext();
    const deviceSize = useDeviceSize();

    return (
        <>
            {active.includes('tripgroup') && (
                <Fieldset
                    style={{ maxWidth: isHomePage ? undefined : '34rem' }}
                    label={showLabel && <FormattedMessage defaultMessage="Reisgezelschap" />}
                    id="tripGroup"
                    width={isFull ? undefined : 1}
                    flex={isFull ? 1 : undefined}
                    dataCy="searchFormTripGroup"
                    className={CLASS_SEARCH_BOX_FIELDSET}
                    overwriteLabelColor={
                        theme.colorAssignments.searchBox?.color &&
                        getAssignmentColor(theme, theme.colorAssignments.searchBox.color)
                    }
                >
                    <StyledTripGroupInputField
                        name="booking"
                        surfaceElementVariant={variant === 'home' ? 'input' : 'searchbar'}
                        className={CLASS_SEARCH_BOX_TRIP_GROUP_FIELD}
                    />
                </Fieldset>
            )}
            {active.includes('datepicker') && (
                <Fieldset
                    label={showLabel && <FormattedMessage defaultMessage="Reisperiode" />}
                    dataCy="searchFormDates"
                    width={isFull ? [1, null, '33%', '30rem'] : 1}
                    className={CLASS_SEARCH_BOX_FIELDSET}
                >
                    {deviceSize === 'mobile' ? (
                        <DatepickerField
                            startName="booking.arrivalDate"
                            endName="booking.departureDate"
                            hideInput
                            hasFlexibleDates={!hideFlexibleOptions}
                            singleDate={false}
                            hideCalendarIcon={hideCalendarIcon}
                            hideHelper={hideHelper}
                        >
                            <SearchDateFieldMobile />
                        </DatepickerField>
                    ) : (
                        <SearchDateField isHomepage={isHomePage} hideFlexibleOptions={hideFlexibleOptions} />
                    )}
                </Fieldset>
            )}
            {active.includes('accommodationType') && (
                <Fieldset
                    label={(isHomePage || searchModalOpen) && <FormattedMessage defaultMessage="Type accommodatie" />}
                    id="accommdationType"
                    width={isFull ? undefined : 1}
                    flex={isFull ? 1 : undefined}
                    dataCy="searchFormAccommdationType"
                    className={CLASS_SEARCH_BOX_FIELDSET}
                    style={{ maxWidth: isHomePage ? undefined : '24rem' }}
                    overwriteLabelColor={
                        theme.colorAssignments.searchBox?.color &&
                        getAssignmentColor(theme, theme.colorAssignments.searchBox.color)
                    }
                >
                    <SelectInput
                        hideChoices
                        ToggleComponent={StyledCaret}
                        options={accommodationTypeOptions}
                        name="accommodationType"
                        thinShadow
                        placeholder={<FormattedMessage defaultMessage="Type verblijf" />}
                        className={CLASS_SEARCH_BOX_ACCOMMODATION_TYPE_FIELD}
                        styles={{
                            control: styles => ({
                                ...styles,
                            }),
                        }}
                    />
                </Fieldset>
            )}
            {brandConfig.searchBox?.hasBrandSearch && isHomePage && (
                <Fieldset
                    label={(isHomePage || searchModalOpen) && <FormattedMessage defaultMessage="Eiland" />}
                    id="brand"
                    width={isFull ? undefined : 1}
                    flex={isFull ? 1 : undefined}
                    dataCy="searchFormIsland"
                    overwriteLabelColor={
                        theme.colorAssignments.searchBox?.color &&
                        getAssignmentColor(theme, theme.colorAssignments.searchBox.color)
                    }
                    className={CLASS_SEARCH_BOX_FIELDSET}
                    style={{ maxWidth: isHomePage ? undefined : '24rem' }}
                >
                    <SelectInput
                        hideChoices
                        ToggleComponent={StyledCaret}
                        options={[
                            {
                                value: 'all',
                                label: intl.formatMessage({
                                    defaultMessage: 'Alle eilanden',
                                }),
                            },
                            ...(brandOptions ?? []),
                        ]}
                        name="brand"
                        thinShadow
                        placeholder={<FormattedMessage defaultMessage="Eiland" />}
                        className={CLASS_SEARCH_BOX_ACCOMMODATION_TYPE_FIELD}
                        styles={{
                            control: styles => ({
                                ...styles,
                            }),
                        }}
                    />
                </Fieldset>
            )}
        </>
    );
};

const StyledTripGroupInputField = styled(TripGroupInputField)`
    z-index: ${({ theme }) => theme.zIndices.dropdown};
    --buttonContainerRadius: calc(${({ theme }) => theme.radius.button} + ${({ theme }) => theme.radius.button}*0.5);
    ${({ theme }) => css`
        --borderRadius: ${theme.radius.textInput};
    `};
`;

export default SearchForm;

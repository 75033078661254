import SelectInput, { OptionType } from '@oberoninternal/travelbase-ds/components/form/SelectInput';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import useSesame from '@oberoninternal/travelbase-ds/hooks/useSesame';
import { Box, Flex } from '@rebass/grid';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { CLASS_SEARCH_BOX_FIELDSET, CLASS_SEARCH_BOX_TRIP_GROUP_FIELD } from '../constants/customizationClassNames';
import Dropdown, { DropdownDescription } from './designsystem/Dropdown';
import Fieldset from './designsystem/Fieldset';
import { SearchBarValues } from './SearchBox';
import { Interactable, interactables, SearchVariant } from './SearchForm';
import TripGroupInputField from './TripGroupInputField';
import SearchDateField from './SearchDateField';

interface Props {
    variant: SearchVariant;
    accommodationTypeOptions: OptionType[];
    searchModalOpen?: boolean;
    active?: Interactable[];
    hideFlexibleOptions?: boolean;
}

const SearchForm: FC<React.PropsWithChildren<Props>> = ({
    variant,
    accommodationTypeOptions,
    searchModalOpen = false,
    active = interactables,
    hideFlexibleOptions,
}) => {
    const isSearchPage = variant === 'searchpage';
    const isHero = variant === 'hero';
    const isAccommodationSearch = variant === 'accommodationSearch';
    const isHomePage = variant === 'home';
    const showLabel = isHomePage || searchModalOpen;

    const isFull = isSearchPage || isHero || isAccommodationSearch;
    const { values } = useFormikContext<SearchBarValues>();
    const sesame = useSesame(false, { closeOnClickOutside: true });
    return (
        <>
            {active.includes('tripgroup') && (
                <Fieldset
                    style={{ maxWidth: isHomePage ? undefined : '34rem' }}
                    label={showLabel && <FormattedMessage defaultMessage="Reisgezelschap" />}
                    id="tripGroup"
                    width={isFull ? undefined : 1}
                    flex={isFull ? 1 : undefined}
                    dataCy="searchFormTripGroup"
                    className={CLASS_SEARCH_BOX_FIELDSET}
                >
                    <StyledTripGroupInputField
                        name="booking"
                        variantStyle={variant}
                        surfaceElementVariant="searchbar"
                        className={CLASS_SEARCH_BOX_TRIP_GROUP_FIELD}
                    />
                </Fieldset>
            )}

            {active.includes('datepicker') && (
                <Fieldset
                    label={showLabel && <FormattedMessage defaultMessage="Reisperiode" />}
                    dataCy="searchFormDates"
                    width={isFull ? [1, null, '33%', '30rem'] : 1}
                    className={CLASS_SEARCH_BOX_FIELDSET}
                >
                    <SearchDateField hideFlexibleOptions={hideFlexibleOptions} isHomepage={isHomePage} />
                </Fieldset>
            )}

            {active.includes('accommodationType') && (
                <Fieldset
                    label={(isHomePage || searchModalOpen) && <FormattedMessage defaultMessage="Type accommodatie" />}
                    id="accommdationType"
                    width={isFull ? undefined : 1}
                    flex={isFull ? 1 : undefined}
                    dataCy="searchFormAccommdationType"
                    className={CLASS_SEARCH_BOX_FIELDSET}
                    style={{ maxWidth: isHomePage ? undefined : '24rem' }}
                >
                    <StyledDropdown
                        ref={sesame.ref}
                        surfaceElement={
                            <>
                                <Flex flexWrap="wrap">
                                    <Box>
                                        <StyledBody variant="tiny">
                                            <FormattedMessage defaultMessage="Type accommodatie" />
                                        </StyledBody>
                                    </Box>
                                    <Box width={1}>
                                        <Box>
                                            <StyledDropdownDescription variant="small" isPlaceholder={false}>
                                                {
                                                    accommodationTypeOptions.find(
                                                        typeOption => typeOption.value === values.accommodationType
                                                    )?.label
                                                }
                                            </StyledDropdownDescription>
                                        </Box>
                                    </Box>
                                </Flex>
                            </>
                        }
                        borderRadius={0}
                        open={sesame.open}
                        onToggle={sesame.onToggle}
                        scrollIntoView
                    >
                        <StyledSelectInput
                            hideChoices
                            options={accommodationTypeOptions}
                            onMenuClose={() => {
                                sesame.onClose();
                            }}
                            name="accommodationType"
                            thinShadow
                            menuPlacement="bottom"
                            menuIsOpen={sesame.open}
                            color="primary"
                            placeholder={<FormattedMessage defaultMessage="Type verblijf" />}
                            components={{
                                Control: () => null,
                            }}
                        />
                    </StyledDropdown>
                </Fieldset>
            )}
        </>
    );
};

const StyledSelectInput = styled(SelectInput)`
    & div {
        position: relative;
        margin-top: 0;
    }
`;

const StyledTripGroupInputField = styled(TripGroupInputField)<{ variantStyle: SearchVariant }>`
    --buttonContainerRadius: calc(${({ theme }) => theme.radius.button} + ${({ theme }) => theme.radius.button}*0.5);

    ${({ variantStyle, theme }) =>
        variantStyle === 'home'
            ? css`
                  --borderRadius: ${theme.radius.textInput};
              `
            : css`
                  --borderRadius: var(--buttonContainerRadius) 0 0 var(--buttonContainerRadius);
              `};
    & > button {
        padding-left: 2rem;
    }
`;

const StyledDropdown = styled(Dropdown)`
    --borderRadius: 0;
    padding-left: 3.2rem;
`;

const StyledBody = styled(Body)`
    color: ${({ theme }) => theme.colors.neutral['60']};
    font-weight: 300 !important;
    line-height: 1.15;
    white-space: nowrap;
`;

const StyledDropdownDescription = styled(DropdownDescription)<{ isPlaceholder: boolean }>`
    ${({ isPlaceholder, theme }) => isPlaceholder && `color: ${theme.colors.neutral['40']}`};
`;
export default SearchForm;

import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import { OptionType } from '@oberoninternal/travelbase-ds/components/form/SelectInput';
import useSesame from '@oberoninternal/travelbase-ds/hooks/useSesame';
import createBorderCss from '@oberoninternal/travelbase-ds/utils/createBorderCss';
import { Flex } from '@rebass/grid';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import * as Yup from 'yup';
import { BrandConfig } from '../constants/brandConfig';
import {
    CLASS_SEARCH_BOX_ACCOMMODATION_TYPE_FIELD,
    CLASS_SEARCH_BOX_BUTTON,
    CLASS_SEARCH_BOX_BUTTON_CONTAINER,
    CLASS_SEARCH_BOX_CONTAINER,
    CLASS_SEARCH_BOX_CONTAINER_MOBILE,
} from '../constants/customizationClassNames';
import { useTenantContext } from '../context/TenantContext';
import { Booking } from '../entities/Booking';
import { PartialDeep } from '../entities/PartialDeep';
import { useUnitFilterPropertiesQuery } from '../generated/graphql';
import { getAccommodationTypeOptions } from '../utils/getAccommodationTypeOptions';
import getDefaultAccommodationType from '../utils/getDefaultAccommodationType';
import BookingText from './BookingText';
import { DatepickerEndInput } from './designsystem/datepicker/Inputs';
import { FormBox } from './designsystem/Fieldset';
import SearchBoxModal from './SearchBoxModal';
import SearchDropdownForm from './SearchDropdownForm';
import SearchForm, { Interactable, SearchVariant } from './SearchForm';
import Calendar from './svg/Calendar.svg';
import TriangleIcon from './svg/TriangleIcon.svg';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import { getAssignmentColor } from '@oberoninternal/travelbase-ds/constants/theme';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { withDefaultBookingValues } from '../utils/trip';
import useAnalyticsSearchBarValues from '../hooks/analytics/useAnalyticsSearchBarValues';

interface Props {
    variant: SearchVariant;
    title?: string;
    isSubmitting?: boolean;
    hideCalendarIcon?: boolean;
    hideSearchIcon?: boolean;
    active?: Interactable[];
    hideHelper?: boolean;
    hideFlexibleOptions?: boolean;
    homeBarLabel?: React.ReactElement<typeof FormattedMessage>;
}

export interface SearchBarValues {
    accommodationType: OptionType['value'];
    brand?: OptionType['value'];
    booking: Booking;
}

export const getInitialSearchBoxValues = (
    brandConfig: BrandConfig,
    values?: PartialDeep<SearchBarValues>
): SearchBarValues => ({
    accommodationType: values?.accommodationType ? values?.accommodationType : getDefaultAccommodationType(brandConfig),
    booking: withDefaultBookingValues(values?.booking ?? {}, brandConfig),
    brand: values?.brand ? values?.brand : 'all',
});

export const getSearchValidationSchema = () =>
    Yup.object().shape({
        booking: Yup.object().shape({
            arrivalDate: Yup.string(),
            departureDate: Yup.string(),
        }),
    });

const SearchBox: FC<React.PropsWithChildren<Props>> = ({
    variant,
    title,
    isSubmitting,
    hideCalendarIcon,
    hideSearchIcon = false,
    active,
    hideHelper,
    hideFlexibleOptions,
    homeBarLabel = false,
}) => {
    const intl = useIntl();
    const { brandConfig } = useTenantContext();
    const accommodationTypeOptions = getAccommodationTypeOptions(intl, brandConfig);
    const { data: unitFilterPropertiesData } = useUnitFilterPropertiesQuery({ ssr: true });
    const islandOptions = unitFilterPropertiesData?.unitFilterProperties?.find(
        property => property.handle === '_brand'
    );

    const { open: searchModalOpen, onOpen, onClose } = useSesame(false);
    const { submitForm, setValues, values } = useFormikContext<SearchBarValues>();
    const translatedAccommodationType = accommodationTypeOptions?.find(
        accommodationTypeOption => accommodationTypeOption.value === values.accommodationType
    )?.label;
    useAnalyticsSearchBarValues(values);

    return (
        <>
            {variant !== 'home' && (
                <MobileSearchBox onClick={onOpen} variant={variant} className={CLASS_SEARCH_BOX_CONTAINER_MOBILE}>
                    {!hideCalendarIcon && <Calendar />}
                    {variant === 'homeBar' && homeBarLabel ? (
                        <StyledBody>{homeBarLabel}</StyledBody>
                    ) : variant === 'homeBar' ? (
                        <StyledBody>
                            <FormattedMessage defaultMessage="Zoek en boek" />
                        </StyledBody>
                    ) : (
                        <BookingText accommodationType={translatedAccommodationType} booking={values.booking} />
                    )}
                    {values.booking.arrivalDate && values.booking.departureDate ? (
                        <StyledDatePickerEditText>
                            <FormattedMessage defaultMessage="Bewerken" />
                        </StyledDatePickerEditText>
                    ) : (
                        <>
                            {variant !== 'homeBar' && (
                                <StyledDatePickerText>
                                    <FormattedMessage defaultMessage="Kies datum" />
                                </StyledDatePickerText>
                            )}
                        </>
                    )}
                    {variant === 'homeBar' && (
                        <MobileContainer
                            variant={variant}
                            data-cy="searchBoxContainer"
                            className={CLASS_SEARCH_BOX_CONTAINER}
                        >
                            <StyledButtonContainer className={CLASS_SEARCH_BOX_BUTTON_CONTAINER}>
                                <StyledSearchButton>
                                    <StyledTriangleIcon />
                                </StyledSearchButton>
                            </StyledButtonContainer>
                        </MobileContainer>
                    )}
                    {!hideSearchIcon && variant !== 'homeBar' && <StyledTriangleIcon />}
                </MobileSearchBox>
            )}

            <SearchBoxModal
                onClose={onClose}
                open={searchModalOpen}
                setValues={setValues}
                submitForm={submitForm}
                values={values}
            />

            <Container variant={variant} data-cy="searchBoxContainer" className={CLASS_SEARCH_BOX_CONTAINER}>
                {title && <StyledTitle variant="large">{title}</StyledTitle>}
                {variant === 'home' && (
                    <SearchForm
                        active={active}
                        brandOptions={islandOptions?.filterProperties?.map(option => ({
                            value: option.handle,
                            label: option.name,
                        }))}
                        variant={variant}
                        accommodationTypeOptions={accommodationTypeOptions}
                        hideCalendarIcon={hideCalendarIcon}
                        hideHelper={hideHelper}
                        hideFlexibleOptions={hideFlexibleOptions}
                    />
                )}

                {variant !== 'home' && (
                    <>
                        <SearchDropdownForm
                            active={active}
                            variant={variant}
                            accommodationTypeOptions={accommodationTypeOptions}
                            hideFlexibleOptions={hideFlexibleOptions}
                        />
                        <StyledButtonContainer className={CLASS_SEARCH_BOX_BUTTON_CONTAINER}>
                            <StyledSearchButton onClick={submitForm}>
                                <StyledTriangleIcon />
                                <StyledSpan>
                                    <FormattedMessage defaultMessage="Zoek" />
                                </StyledSpan>
                            </StyledSearchButton>
                        </StyledButtonContainer>
                    </>
                )}

                {variant === 'home' && (
                    <SearchButton
                        size="large"
                        type="submit"
                        submitting={isSubmitting}
                        data-cy="searchButton"
                        className={CLASS_SEARCH_BOX_BUTTON}
                    >
                        <FormattedMessage defaultMessage="Zoeken" />
                    </SearchButton>
                )}
            </Container>
        </>
    );
};

export default SearchBox;

const leftBorderRadius = css`
    border-top-left-radius: var(--buttonContainerRadius) !important;
    border-bottom-left-radius: var(--buttonContainerRadius) !important;
`;

const rightBorderRadius = css`
    border-top-right-radius: var(--buttonContainerRadius) !important;
    border-bottom-right-radius: var(--buttonContainerRadius) !important;
`;
const StyledSearchButton = styled(Button)`
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
    height: 100%;
`;
const StyledButtonContainer = styled(Flex)`
    height: 100%;
    ${createBorderCss('1px')}
    ${rightBorderRadius}
    --border-color: ${({ theme }) => theme.colors.neutral['20']};
`;

const StyledTriangleIcon = styled(TriangleIcon)`
    transform: rotateY(0deg) rotate(90deg);
`;

const StyledBody = styled(Body)`
    font-weight: bold;
`;

const StyledDatePickerText = styled.span`
    width: 12rem;
    padding-left: ${({ theme }) => theme.spacing['30_Small']};

    border-left: 1px solid ${({ theme }) => theme.colors.neutral['30']};
    margin-left: 5px;
`;

const StyledDatePickerEditText = styled.span`
    width: 10rem;
    padding-left: ${({ theme }) => theme.spacing['30_Small']};
    border-left: 1px solid ${({ theme }) => theme.colors.neutral['30']};
    margin-left: 5px;
`;

const StyledTitle = styled(Title)`
    ${({ theme }) =>
        theme.colorAssignments.searchBox?.color &&
        `color: ${getAssignmentColor(theme, theme.colorAssignments.searchBox.color)}`};
    margin-top: ${({ theme }) => theme.spacing['60_Large']};
    margin-bottom: ${({ theme }) => theme.spacing['40_Standard']};
`;
const MobileSearchBox = styled(Flex).attrs({
    justifyContent: 'space-between',
    alignItems: 'center',
})<{ variant: SearchVariant }>`
    width: 100%;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        width: ${({ variant }) => (variant === 'searchMap' ? '36rem' : '100%')};
        max-width: 36rem;
    }
    padding-left: 1.6rem;
    height: 4.8rem;
    border: 1px solid ${({ theme }) => theme.colors.neutral['20']};
    background: ${({ theme }) => theme.colors.neutral[0]};
    box-shadow: 0px 0.2rem 0.4rem 0px rgba(59, 118, 160, 0.02), 0px 0.4rem 1.2rem 0px rgba(59, 118, 160, 0.04);
    border-radius: ${({ theme }) => theme.radius.button};
    cursor: pointer;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
        font-size: 1.6rem;
    }

    @media screen and (min-width: ${({ variant, theme }) =>
            variant === 'hero' || variant === 'accommodationSearch' || variant === 'searchpage' || variant === 'homeBar'
                ? theme.mediaQueries.s
                : '1440px'}) {
        display: none;
    }
`;

const getMaxWidth = (variant: SearchVariant) => {
    if (variant === 'hero') {
        return '109rem';
    }

    if (variant === 'accommodationSearch') {
        return '72rem';
    }

    return '100rem';
};

const StyledSpan = styled.span`
    @media screen and (max-width: ${({ theme }) => theme.mediaQueries.m}) {
        display: none;
    }
`;

const MobileContainer = styled(Flex)<{ variant: SearchVariant }>`
    --buttonContainerRadius: calc(${({ theme }) => theme.radius.button} + ${({ theme }) => theme.radius.button}*0.5);
    flex-direction: column;
    display: ${({ variant }) => (variant !== 'home' ? 'flex' : 'flex')};
    height: 100%;
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        max-width: ${({ variant }) => getMaxWidth(variant)};
    }

    ${({ variant }) =>
        variant === 'home'
            ? css`
                  padding: 2.4rem;

                  @media screen and (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
                      width: 43.2rem;
                      background: ${({ theme }) =>
                          theme.colorAssignments.searchBox?.backgroundColor
                              ? getAssignmentColor(theme, theme.colorAssignments.searchBox.backgroundColor)
                              : theme.colors.neutral['0']};
                      box-shadow: 0px 16px 80px 0px rgba(0, 0, 0, 0.1);
                      border-radius: 1.2rem;
                  }

                  @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
                      right: 10.4rem;
                  }
              `
            : css`
                  > * {
                      z-index: 2;

                      :last-of-type {
                          ${rightBorderRadius}
                      }
                  }

                  > :last-child {
                      /* add border radius if the last child is the datepicker */
                      ${DatepickerEndInput} {
                          ${rightBorderRadius}
                      }
                      /* add border radius if the last child is the accommodationtype select */
                      .${CLASS_SEARCH_BOX_ACCOMMODATION_TYPE_FIELD} {
                          > div:first-of-type {
                              ${rightBorderRadius}
                          }
                      }
                  }

                  > div {
                      margin-bottom: 0;
                  }
              `};
`;

const Container = styled(Flex)<{ variant: SearchVariant }>`
    --buttonContainerRadius: calc(${({ theme }) => theme.radius.button} + ${({ theme }) => theme.radius.button}*0.5);
    flex-direction: column;
    display: ${({ variant }) => (variant !== 'home' ? 'none' : 'flex')};
    min-height: 0;
    @media screen and (min-width: ${({ variant, theme }) =>
            variant === 'hero' || variant === 'accommodationSearch' || variant === 'searchpage' || variant === 'homeBar'
                ? theme.mediaQueries.s
                : '1440px'}) {
        flex-direction: ${({ variant }) => (variant === 'home' ? 'column' : 'row')};
        ${({ variant }) =>
            variant !== 'home' &&
            `
            height: 4.8rem;
        `};
        display: flex;
        > ${FormBox} + ${FormBox} {
            margin-left: 1px;
        }
    }
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        max-width: ${({ variant }) => getMaxWidth(variant)};
    }

    ${({ variant }) =>
        variant === 'home'
            ? css`
                  padding: 3.2rem 2.4rem;

                  @media screen and (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
                      width: 43.2rem;
                      background: ${({ theme }) =>
                          theme.colorAssignments.searchBox?.backgroundColor
                              ? getAssignmentColor(theme, theme.colorAssignments.searchBox.backgroundColor)
                              : theme.colors.neutral['0']};
                      box-shadow: 0px 16px 80px 0px rgba(0, 0, 0, 0.1);
                      border-radius: 1.2rem;
                  }
                  height: 100%;
                  @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
                      right: 10.4rem;
                  }
              `
            : css`
                  > * {
                      box-shadow: 0 16px 24px 0 rgba(59, 118, 160, 0.03), 0 24px 80px 0 rgba(59, 118, 160, 0.05);
                      z-index: 1;
                      :hover {
                          z-index: 2;
                      }
                      :first-of-type {
                          ${leftBorderRadius}
                      }
                      :last-of-type {
                          ${rightBorderRadius}
                      }
                  }

                  > :last-child {
                      /* add border radius if the last child is the datepicker */
                      ${DatepickerEndInput} {
                          ${rightBorderRadius}
                      }
                      /* add border radius if the last child is the accommodationtype select */
                      .${CLASS_SEARCH_BOX_ACCOMMODATION_TYPE_FIELD} {
                          > div:first-of-type {
                              ${rightBorderRadius}
                          }
                      }
                  }

                  @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
                      flex: 1;
                  }
                  > div {
                      margin-bottom: 0;
                  }
              `};
`;

const SearchButton = styled(Button)`
    margin-top: ${({ theme }) => theme.spacing['40_Standard']};
    border-radius: ${({ theme }) => theme.radius.button};
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
        margin-top: ${({ theme }) => theme.spacing['50_Semi']};
    }
`;

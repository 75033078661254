import { Box, Flex } from '@rebass/grid';
import Dropdown, { DropdownDescription } from './designsystem/Dropdown';
import { useIntl } from 'react-intl';
import React from 'react';
import { useFormikContext } from 'formik';
import { SearchBarValues } from './SearchBox';
import { useDatepickerContext } from './designsystem/datepicker/Context';
import { getSearchDateLabel } from './SearchDateField';

const SearchDateFieldMobile = () => {
    const { values } = useFormikContext<SearchBarValues>();
    const { formatDate } = useIntl();
    const {
        datepicker: [, { set }],
    } = useDatepickerContext();

    return (
        <Dropdown
            open={false}
            onToggle={() => set({ open: true })}
            surfaceElement={
                <Flex flexWrap="wrap">
                    <Box width={1}>
                        <Box>
                            <DropdownDescription>{getSearchDateLabel(values.booking, formatDate)}</DropdownDescription>
                        </Box>
                    </Box>
                </Flex>
            }
        />
    );
};

export default SearchDateFieldMobile;
